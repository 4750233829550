// Variable overrides

$theme: #011851;
$primary: #19f9b9;
$secondary: #3d58c3;
$white: #fff;
$info: #3d58c3;
$link: #20a8d8;

$linkColor: #fe2287;
$borderGrey: #e0e0e0;
$bgGrey: #f6f6f6;
$darkGray: #24272c;
$bgDark: #01091e;
$darkBlue: #011851;

$red: red;